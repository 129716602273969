<template>
<div class="report-card d-flex flex-column gap-3">
    <div class="header d-flex align-items-center gap-2" @click="toggleDetails">
    <img :class="arrowIcon" class="arrow-icon"  src="@/assets/icons/down-arrow.png"/>
    <div class="d-flex gap-2 align-items-end">
        <h5 class="m-0 p-0">{{ recordType }}</h5>
        <p class="subheading">vom {{ formattedDate }}</p>
    </div>
    </div>
    <div v-if="showDetails" class="details">
    <div v-if="record.record_type === 'absence'">
        <p v-if="record.absence_type === '1'">Art: Kind kommt später</p>
        <p v-if="record.absence_type === '2'">Art: Kind kommt nicht.</p>
        <p v-if="record.absence_type === '3'">Art: Kind ist krank.</p>
    </div>
    <p v-if="record.date"> Tag: {{ record.date }}</p>
    <p v-if="record.absence_day"> Tag: {{ record.absence_day }}</p>
    <p v-if="record.time && record.time != '00:00:00'"> Zeit: {{ record.time }}</p>
    <p v-if="record.absence_time && record.absence_time != '00:00:00'">Zeit: {{ record.absence_time }}</p>
    <p v-if="record.absence_duration_from && record.absence_duration_until">
        Dauer: {{ record.absence_duration_from }} - {{ record.absence_duration_until }}
    </p>
    <p v-if="record.record_type === 'absence'">
        <span v-if="record.absence_reason">Grund: {{ record.absence_reason }}</span>
        <span v-if="record.absence_type === '3'"> Krankheit</span>
    </p>
    <p v-if="record.record_type === 'pickup'">
        Abholart: {{ record.pickup_type }} 
    </p>
    <p v-if="record.record_type === 'pickup'">
        Ziel: {{ record.destination }}
    </p>
    </div>
</div>
</template>

<script>
export default {
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDetails: false,
    };
  },
  computed: {
    recordType() {
      return this.record.record_type === "absence" ? "Abwesenheit" : "Abholung";
    },
    formattedDate() {
      return this.formatDate(this.record.created_at);
    },
    arrowIcon() {
      return this.showDetails ? "arrow-down" : "arrow-right";
    },
  },
  methods: {
    formatDate(input) {
      const date = new Date(input);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    },
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },
  },
};
</script>

<style scoped>
.report-card {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 1px 1px #ccc;
  cursor: pointer;
}

.subheading {
  font-size: 0.8rem;
  color: #666;
}

.arrow-icon {
  font-size: 1.2rem;
  transition: transform 0.2s;
}

.arrow-right {
  transform: rotate(-90deg);
}

.arrow-down {
  transform: rotate(0deg);
}

.details {
  padding: 10px;
  border-top: 1px solid #ddd;
  margin-top: 10px;
}

img {
    width:35px;
}
</style>
