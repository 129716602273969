import { defineStore } from 'pinia';
import logger from '../utils/logger';
import environment from './environment';

export const useUserStore = defineStore('user', {
  state: () => ({
    isAuthenticated: false,
    authToken: null,
    name: null,
    class: null,
    state: null,
  }),

  actions: {
    async login(token) {
      logger.addLog(`Login attempt with token: ${token}`);

      const userInfo = await this.getUserInfo(token);
      if (userInfo === false) {
        logger.addLog("Token validation failed.");
        this.isAuthenticated = false;
        return false;
      } else {
        logger.addLog("Token validated successfully.");
        sessionStorage.setItem('access_token', token);
        this.isAuthenticated = true;
        this.authToken = token;
        this.name = userInfo.name;
        this.class = this.determineClass(userInfo.groups);
        return true;
      }
    },

    async logout() {
      logger.addLog("Logging out...");
      sessionStorage.setItem('logoutFlag', 'true'); // Set logout flag
         // Redirect the user to the IServ logout URL
        const iservLogoutUrl = `https://gs-waggum.de/iserv/auth/logout?redirect_uri=${encodeURIComponent(window.location.origin + '/login')}`;
        //const iservLogoutUrl = "https://gs-waggum.de/iserv/"
        this.isAuthenticated = false;
        this.authToken = null;
        sessionStorage.removeItem('access_token');

        // Redirect the browser to the IServ logout URL
        window.location.href = iservLogoutUrl;
        return true;
    },

    async getUserInfo(token) {
      try {
          logger.addLog("Retrieving user info with token.");
  
          const response = await fetch(environment.getBaseUrlPHP() + "/getUserInfo.php", {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: new URLSearchParams({
                  token: process.env.VUE_APP_SECRET_TOKEN,
                  access_token: token
              })
          });
  
          if (response.ok) {
              const data = await response.json();
              logger.addLog("User info retrieved successfully.");
              return { name: data.name, groups: data.groups };
          }
  
          logger.addLog("User info retrieval failed.");
          return false;
      } catch (error) {
          logger.addLog(`Error during user info retrieval: ${error.message}`);
          return false;
      }
  },

    async initializeAuth() {
      const token = sessionStorage.getItem('access_token');
      logger.addLog(`Initializing auth. Found token: ${token}`);

      if (token) {
        const loggedIn = await this.login(token);
        if (!loggedIn) {
          logger.addLog("Token invalid during auth initialization. Logging out."); 
        }
      } else {
        logger.addLog("No token found, logging out.");
      }
    },

    determineClass(groups) {
      const classPrefixes = [
          "5d", "5c", "5b", "5a",
          "4d", "4c", "4b", "4a",
          "3d", "3c", "3b", "3a",
          "2d", "2c", "2b", "2a",
          "1d", "1c", "1b", "1a"
      ];
      const allUserClasses = [];
  
      // Iterate over the groups object to find classes
      Object.values(groups).forEach(group => {
          const groupName = group.act;
          const matchedClass = classPrefixes.find(prefix => groupName.includes(prefix));
          if (matchedClass) {
              allUserClasses.push(matchedClass);
          }
      });
  
      // Sort classes by grade level and alphabetically within each grade
      allUserClasses.sort((a, b) => {
          const gradeA = parseInt(a.charAt(0), 10);
          const gradeB = parseInt(b.charAt(0), 10);
          const classA = a.charAt(1);
          const classB = b.charAt(1);
  
          if (gradeA !== gradeB) {
              return gradeB - gradeA; // Higher grade level first
          } else {
              return classA.localeCompare(classB); // Alphabetical order within the same grade
          }
      });
  
      // Return the highest class
      return allUserClasses.length > 0 ? allUserClasses[0] : null;
  }
  
  }
});
