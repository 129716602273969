<template>
  <div class="profile">
    <h1>Profil</h1>
    <div class="p-4 d-flex">
      <div class="d-flex flex-column justify-content-around m-3">
        <p>Name:</p>
        <p>Klasse:</p>
      </div>
      <div class="d-flex flex-column justify-content-around m-3">
        <p>{{ name }}</p>
        <p v-if="userClass">{{ userClass }}</p>
        <p v-else class="feedback-error">
          Fehler: Es wurde keine Klasse auf IServ zugeteilt. Bitte wenden Sie sich an das Sekretariat.
        </p>
      </div>
    </div>
    <div>
      <h3>Letzte 3 Meldungen</h3>
      <ReportCard v-for="(record, index) in lastThreeRecords" :key="record.record_type + record.id + index" :record="record"></ReportCard>
      <p v-if="lastThreeRecords.length === 0">Keine Meldungen gefunden.</p>
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/utils/user";
import environment from "@/utils/environment";
import ReportCard from "./secondary/ReportCard.vue";

export default {
  components: {
    ReportCard,
  },
  data() {
    const userStore = useUserStore();

    return {
      name: userStore.name,
      userClass: userStore.class || "Keine Klasse auf IServ hinterlegt. Bitte wenden Sie sich an das Sekretariat.",
      absences: [],
      pickups: [],
      errorMessage: false,
    };
  },
  computed: {
    allRecords() {
      return [
        ...this.absences.map((absence) => ({ ...absence, record_type: "absence" })),
        ...this.pickups.map((pickup) => ({ ...pickup, record_type: "pickup" })),
      ].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    },
    lastThreeRecords() {
      return this.allRecords.slice(0, 3);
    },
  },
  mounted() {
    this.fetchRecords();
  },
  methods: {
    formatDate(input) {
      const date = new Date(input);
      const day = String(date.getDate()).padStart(2, '0'); // Ensure 2 digits
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    },
    async fetchRecords() {
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("class", this.userClass);
      formData.append('token', process.env.VUE_APP_SECRET_TOKEN); 

      try {
        const response = await fetch(environment.getBaseUrlPHP() + "/getRecords.php", {
          method: "POST",
          body: formData,
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        if (result.absences && result.pickups) {
          this.absences = result.absences;
          this.pickups = result.pickups;
          this.errorMessage = false;
          console.log(result);
        } else {
          this.absences = [];
          this.pickups = [];
          this.errorMessage = true;
          console.error("Unexpected response:", result);
        }
      } catch (error) {
        this.absences = [];
        this.pickups = [];
        this.errorMessage = true;
        console.error("Error fetching records:", error);
      }
    },
  },
};
</script>

